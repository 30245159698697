import React from 'react';
import * as styles from './project.module.scss';
import Card from '../../UI/Card/card';
import Button from '../../UI/Button/button';

import qd from '../../../images/Home/qd.png';
import weCover from '../../../images/Home/weCover.png'
import firma from '../../../images/Home/firma.png';
import facial from '../../../images/Home/facial.png';
import { useTranslation } from "gatsby-plugin-react-i18next"

const ProjectSection = () => {

    const {t} = useTranslation();

	return (
		<section className={styles.projectSection} id='projectSection'>
			<div className={styles.containProject}>
                <div className={styles.containTitle}    
                    data-sal-delay='400'
                    data-sal='slide-up'
                    data-sal-duration='400'
                    data-sal-easing='ease-out-back'>
                    <h2>{t("project.expertise")}</h2>
                </div>
                <div className={styles.containBody}>
                    <div className={styles.containTitle}
                       data-sal-delay='400'
                       data-sal='slide-up'
                       data-sal-duration='400'
                       data-sal-easing='ease-out-back'>
                        <p>
                            {t("project.design")}
                        </p>
                    </div>
                    <div className={styles.containCars}>
                    <Button className={styles.Button} linkTo='/projects/wecover' isLink >                        
                        <Card
                            img = {weCover}
                            title={t("project.weCover.title")} 
                            description={t("project.weCover.description")}
                            color='#a9b6eb'
                            list={[t("project.weCover.list.0"), t("project.weCover.list.1")]}
                            />                    
                    </Button>
                    
                    <Button className={styles.Button} linkTo='/projects/cabletel' isLink >  
                    <Card 
                        img = {facial}
                        title={t("project.cableTel.title")} 
                        description={t("project.cableTel.description")}
                        color='#ea9e5d'
                        list={[t("project.cableTel.list.0"), t("project.cableTel.list.1"), t("project.cableTel.list.2")]}/>
                    </Button>
                    <Button className={styles.Button} linkTo='/projects/fdigital' isLink >  
                    <Card 
                        img = {firma}
                        title={t("project.digitalSignature.title")} 
                        description={t("project.digitalSignature.description")}
                        color='#f081c6'
                        list={[t("project.digitalSignature.list.0"), t("project.digitalSignature.list.1"), t("project.digitalSignature.list.2")]}/>
                    </Button>
                    <Button className={styles.Button} linkTo='/projects/qdetect' isLink >  
                    <Card 
                        img = {qd}
                        title={t("project.TCFAutos.title")} 
                        description={t("project.TCFAutos.description")}
                        color='#99d590'
                        list={[t("project.TCFAutos.list.0"), t("project.TCFAutos.list.1")]}/>
                    </Button>
                </div>
                </div>

			</div>
		</section>
	);
};
export default ProjectSection;
