// extracted by mini-css-extract-plugin
export var centerContain = "values-module--centerContain--dbb2c";
export var containBody = "values-module--containBody--5f1f7";
export var containLeft = "values-module--containLeft--63bb1";
export var containLinks = "values-module--containLinks--51f88";
export var containLinksDescription = "values-module--containLinksDescription--11861";
export var containLinksTitle = "values-module--containLinksTitle--f0d5b";
export var containRight = "values-module--containRight--09564";
export var containSection = "values-module--containSection--b16ba";
export var containTitle = "values-module--containTitle--86137";
export var valuesSection = "values-module--valuesSection--88927";