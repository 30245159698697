import React, { useEffect } from "react";
import Layout from "../components/UI/layout";
import SEO from "../components/UI/seo";
import Header from "../components/Home/Header/header";
import Process from "../components/Home/Process/process";
import Banner from "../components/Home/Banner/banner";
import Service from "../components/Home/Service/service";
import Compani from "../components/Home/Compani/compani"
import Team from "../components/Home/Team/team";
import Project from "../components/Home/Project/project";
import Values from "../components/Home/Values/values";
import { graphql } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"

const IndexPage = () => {

  const { changeLanguage } = useI18next();

  const isInArg = async () => {
    const location = await fetch(`https://geolocation-db.com/json/`);
    const jsonLocation = await location.json()
    return await jsonLocation.country_code === "AR";
  }

  useEffect(() => {
    if (!localStorage.getItem('visitado')) {
      isInArg()
        .then(arg => {
          arg ? changeLanguage('es') : changeLanguage('en');
          localStorage.setItem('visitado', '1');
        })
    }
  }, [])

  return (
    <Layout>
      <SEO title="Codeicus | Home" />
      <Header />
      <Service />

      <Banner />
      <Project />

      <Values />
      <Team />
      <Compani />
    </Layout>
  )
}

/*<Process /> ---Contiene el componente "Nuestro Proceso"--se oculto debido a requerimiento de TK CODSITIO-84----   */


export default IndexPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;