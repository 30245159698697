import React from 'react';

import * as styles from './banner.module.scss';
import { useTranslation } from "gatsby-plugin-react-i18next";


const BannerSection = () => {

    const {t} = useTranslation();

	return (
		<section className={styles.bannerSection}>
            <div className={styles.containBanner}>
                <div className={styles.centerBanner}>
                    <h4 
                        data-sal-delay='300'
                        data-sal='slide-right'
                        data-sal-duration='700'
                        data-sal-easing='ease-out-back'>
                            {t("banner.solutions")}
                    </h4>
                    <div 
                        data-sal-delay='300'
                        data-sal='slide-left'
                        data-sal-duration='700'
                        data-sal-easing='ease-out-back'
                        className={styles.contactUs}>
                        <span>
                            {t("banner.callUs")}
                        </span>
                    </div>
                    {/*
                    <div className={styles.containBtns}>
                        <Button linkTo='/contact' isLink type='black'>
                            Conoce mas
                        </Button>
                        <Button linkTo='/contact' isLink type='black'>
                            Casos de éxito
                        </Button>
                        <Button linkTo='/contact' isLink type='black'>
                            Contactanos
                        </Button>
                    </div>
                    */}
                </div>

            </div>
		</section>
	);
};
export default BannerSection;