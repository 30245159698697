import React from 'react';
import * as styles from './service.module.scss';
import Card from '../../UI/Card/card';
import { useTranslation } from "gatsby-plugin-react-i18next"


import ico1 from '../../../images/Home/ico1.png';
import ico2 from '../../../images/Home/ico2.png';
import ico3 from '../../../images/Home/ico3.png';

const ServiceSection = () => {
    const {t} = useTranslation();
   
	return (
		<section className={styles.serviceSection}>
            <div className={styles.containServicios}>
                <div className={styles.title}>
                    <h2 data-sal-delay='300'
					data-sal='slide-right'
					data-sal-duration='700'
					data-sal-easing='ease-out-back'>{t("service.needs")}</h2>
                    <p
                    data-sal-delay='300'
					data-sal='slide-left'
					data-sal-duration='700'
					data-sal-easing='ease-out-back'
                    >{t("service.growth")}</p>
                </div>
                <div className={styles.containCards}>
                    <Card
                        img = {ico1}
                        title={t("service.itConsulting.title")} 
                        description={t("service.itConsulting.description")}
                        color='#a9b6eb'
                        list={[t("service.itConsulting.list.0"), t("service.itConsulting.list.1"), t("service.itConsulting.list.2")]}/>
                   
                    <Card 
                        img = {ico3}
                        title={t("service.development.title")} 
                        description={t("service.development.description")}
                        color='#b7e6d3'
                        list={[t("service.development.list.0"), t("service.development.list.1"), t("service.development.list.2")]}/>
                    <Card 
                        img = {ico2}
                        title={t("service.maintenance.title")} 
                        description={t("service.maintenance.description")}
                        color='#ea9eed'
                        list={[t("service.maintenance.list.0"), t("service.maintenance.list.1"), t("service.maintenance.list.2")]}/>
                </div>
            </div>     
        </section>
	);
};
export default ServiceSection;