import React from 'react';
import * as styles from './values.module.scss';
import aprendizaje from '../../../images/Home/aprendizaje.png';
import compromiso from '../../../images/Home/compromiso.png';
import compartir from '../../../images/Home/compartir.png';
import innovacion from '../../../images/Home/innovacion.png';
import { useTranslation } from "gatsby-plugin-react-i18next"


const ValuesSection = () => {

    const {t} = useTranslation();

	return (
		<section className={styles.valuesSection}>
            <div className={styles.containSection}>
                <div className={styles.centerContain}>
                    <div className={styles.containTitle}
                        data-sal-delay='500'
                        data-sal='zoom-in'
                        data-sal-duration='700'
                        data-sal-easing='ease-out-back'>
                        <h2>{t("values.searchSolutions")}</h2>
                        <p>{t("values.stamp")}</p>
                    </div>
                    <div className={styles.containBody}>
                        <div className={styles.containLeft}>
                            <div 
                                data-sal-delay='400'
                                data-sal='slide-right'
                                data-sal-duration='700'
                                data-sal-easing='ease-out-back'>
                                <img src={innovacion} alt='imagen de referencia' />
                                <h3>{t("values.innovation")} </h3>
                                <p>{t("values.creativesolutions")}</p>
                            </div>
                            <div
                                data-sal-delay='400'
                                data-sal='slide-right'
                                data-sal-duration='700'
                                data-sal-easing='ease-out-back'>
                                <img src={aprendizaje} alt='imagen de referencia' />
                                <h3>{t("values.learning")} </h3>
                                <p>{t("values.weLike")}</p>
                            </div>
                        </div>
                        <div className={styles.containRight}>
                             <div 
                                data-sal-delay='400'
                                data-sal='slide-left'
                                data-sal-duration='700'
                                data-sal-easing='ease-out-back'>
                                <img src={compromiso} alt='imagen de referencia' />
                                <h3>{t("values.commitment")} </h3>
                                <p>{t("values.assume")}</p>
                            </div>
                            <div
                                data-sal-delay='400'
                                data-sal='slide-left'
                                data-sal-duration='700'
                                data-sal-easing='ease-out-back'>                           
                                <img src={compartir} alt='imagen de referencia' />
                                <h3>{t("values.share")} </h3>
                                <p>{t("values.respect")}</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.containLinks}>
                        <div className={styles.containLinksTitle} 
                            data-sal-delay='500'
                            data-sal='zoom-in'
                            data-sal-duration='700'
                            data-sal-easing='ease-out-back'>
                            <h2>{t("values.initiatives")}</h2>      
                            <p>{t("values.bilieve")}</p>
                        </div>
                        <div className={styles.containLinksDescription}>
                                
                                <div  
                                    data-sal-delay='400'
                                    data-sal='slide-up'
                                    data-sal-duration='700'
                                    data-sal-easing='ease-out-back'>
                                    <a href='https://medium.com/somos-codeicus/cultura/home' target="_blank" rel="noopener noreferrer">
                                        <p>{t("values.culture")}</p>
                                        <span>{t("values.code")}</span>
                                    </a>
                                </div>
                                <div
                                    data-sal-delay='400'
                                    data-sal='slide-up'
                                    data-sal-duration='700'
                                    data-sal-easing='ease-out-back'> 
                                    <a href='https://medium.com/somos-codeicus/observatorio-de-tecnolog%C3%ADas/home' target="_blank" rel="noopener noreferrer">
                                        <p>{t("values.observatory")}</p>
                                        <span>{t("values.learnTogether")}</span>
                                    </a>
                                </div>
                                <div
                                    data-sal-delay='400'
                                    data-sal='slide-up'
                                    data-sal-duration='700'
                                    data-sal-easing='ease-out-back'>
                                    <a href='https://medium.com/somos-codeicus/la-factory/home' target="_blank" rel="noopener noreferrer">
                                        <p>{t("values.factory")}</p>
                                        <span>{t("values.work")}</span>
                                    </a>
                                </div>
                        </div>
                    </div>               
                </div>            
            </div>
		</section>
	);
};
export default ValuesSection;
