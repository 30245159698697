// extracted by mini-css-extract-plugin
export var bera = "compani-module--bera--f6d60";
export var companiSection = "compani-module--companiSection--8b8e6";
export var containCompani = "compani-module--containCompani--4ebd3";
export var containImg = "compani-module--containImg--e86ba";
export var containLogo = "compani-module--containLogo--1c763";
export var containLogoCompani = "compani-module--containLogoCompani--fd180";
export var containTitle = "compani-module--containTitle--ac63c";
export var lenovo = "compani-module--lenovo--83545";
export var lexus = "compani-module--lexus--cac60";
export var tcfa = "compani-module--tcfa--e457a";
export var wecover = "compani-module--wecover--cc285";