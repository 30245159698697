import React from 'react';
import * as styles from './team.module.scss';
import Button from '../../UI/Button/button';
import { useTranslation } from "gatsby-plugin-react-i18next"


const TeamSection = () => {

    const {t} = useTranslation();

	return (
		<section className={styles.teamSection}>
            <div className={styles.containTeam}>
                <div className={styles.containTitle}
                    data-sal-delay='300'
                    data-sal='zoom-in'
                    data-sal-duration='700'
                    data-sal-easing='ease-out-back'>
                    <h4>{t("team.part")} <br /> {t("team.theTeam")}</h4>
                    <p>{t("team.benefits")}</p>
                </div>
                <div className={styles.containBtn}>
                    <Button linkTo='/contact' isLink type='black'>
                    {t("team.apply")}
                    </Button>
                </div>
            </div>     
        </section>
	);
};
export default TeamSection;